import React, { useEffect } from 'react';
import { Route, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Markdown from 'react-markdown';

import CookiesBanner from 'frontend-common/src/Components/CookiesBanner/CookiesBanner';
import GTag from 'frontend-common/src/Components/GTag/GTag';
import Login from 'frontend-common/src/Screens/Login';
import MetadataContext from 'frontend-common/src/contexts/metadata';
import useAuth from 'frontend-common/src/Hooks/useAuth';
import useErrors from 'frontend-common/src/Hooks/useErrors';

import About from './Screens/About';
import Help from './Screens/Help';
import Home from './Screens/Home';
import List from './Screens/List';
import NewProtein from './Screens/NewProtein';
import Privacy from './Screens/Privacy';
import Submit from './Screens/Submit';
import ToS from './Screens/ToS';
import View from './Screens/View';

import settings from './content/settings/settings.json';
import about from './content/screens/about.json';
import help from './content/screens/help.json';
import privacy from './content/screens/privacy.json';
import tos from './content/screens/tos.json';

const history = createBrowserHistory();

const metadata = {
  contacts: settings.contacts,
  footerMessage: (
    <Markdown>{ settings.footer }</Markdown>
  ),
  links: [
    about.published && {
      route: '/about',
      title: about.title,
    },
    help.published && {
      route: '/help',
      title: help.title,
    },
    tos.published && {
      route: '/tos',
      title: tos.title,
    },
    privacy.published && {
      route: '/privacy',
      title: privacy.title,
    },
  ].filter(x => !!x),
  logo: '../static/media/stamp-logo.svg',
  siteTitle: settings.title,
};

const LoginComponent = () => <Login onLoginGoTo="/manage" />;

const App = () => {
  const { add } = useErrors();
  const { AuthSession, IsAdmin, logout } = useAuth();

  useEffect(() => {
    if (AuthSession && !IsAdmin) {
      add('You are not allowed to use this platform.');
      logout();
    }
  }, [ AuthSession, IsAdmin, add, logout ]);

  return (
    <MetadataContext.Provider
      value={ metadata }
    >
      <GTag />
      <CookiesBanner />
      <Router history={ history }>
        <Route exact path="/" component={ Home } />
        <Route path="/submit" component={ Submit } />
        { about.published && <Route exact path="/about" component={ About } /> }
        { help.published && <Route exact path="/help" component={ Help } /> }
        { privacy.published && <Route exact path="/privacy" component={ Privacy } /> }
        { tos.published && <Route exact path="/tos" component={ ToS } /> }
        <Route
          exact
          path="/login"
          component={ LoginComponent }
        />
        <Route exact path="/manage" component={ NewProtein } />
        <Route path="/database/:name" component={ View } />
        <Route exact path="/database" component={ List } />
      </Router>
    </MetadataContext.Provider>
  );
};

export default App;
